<i18n>
{
  "de": {
    "heatingHeading": "Heizungsersatz",
    "nameTitle": "Energieträger",
    "initialTitle": "Initialkosten",
    "perKwTitle": "Leistungsabhängig"
  }
}
</i18n>

<template>
  <edit-container :bind-form-state="formState" @container="onFormEvent" class="edit-investment-costs">
    <h2>{{ $t('heatingHeading') }}</h2>
    <detail-list class="heating-costs-table">
      <template #header>
        <span>{{ $t('nameTitle') }}</span>
        <span>{{ $t('initialTitle') }}</span>
        <span>{{ $t('perKwTitle') }}</span>
      </template>
      <li v-for="(heating, heatingKey) in model" :key="heatingKey">
        <div>
          {{ $t(`_heatingTypes.${heatingKey}`) }}
        </div>
        <div>
          <numeric-input v-model="heating.initial" :edit="isEditing" optional :units="'CHF'" />
        </div>
        <div>
          <numeric-input v-model="heating.per_kw" :edit="isEditing" optional :units="'CHF/kW'" />
        </div>
      </li>
    </detail-list>
  </edit-container>
</template>
<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin'
import EditContainer from '@/components/shared/forms/EditContainer'

import DetailList from '@/components/shared/lists/DetailList'
import NumericInput from '@/components/shared/forms/NumericInput'

export default {
  mixins: [
    ApiFormMixin
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation */
  ],
  components: {
    DetailList,
    NumericInput,
    EditContainer
  },
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapActions({
      updateParams: 'portfolio/updateParams'
    }),
    resetModel () {
      this.model = this.portfolio.investment_costs['heating']
    },
    async saveHandler () {
      await this.updateParams({
        id: this.portfolio.id,
        params: {
          'investment_costs': {
            'heating': this.model
          }
        }
      })
    }
  }
}
</script>
